<template>
  <div>
    <!-- <core-toolbar /> -->
    <!-- <core-drawer /> -->
    <v-main
      id="dashboard-view"
      dark>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'DashboardView',
  metaInfo () {
    return {
      title: 'Price Management System'
    }
  }
}
</script>

<style>
.v-main{
  background:white;
}
</style>
